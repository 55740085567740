import { Group, Object3D } from 'three';
import { buildBurners } from './burners.js';
import { AssetLoader } from '../../shared/AssetLoader.js';
import { Materials } from '../../shared/Materials.js';

const BACK_BURNER_OFFSET = -0.28;

export class RangeTopOptions {
  /** @typedef {import("three/examples/jsm/loaders/GLTFLoader.js").GLTF} GLTF */

  /**
   * @typedef {Object} Cache
   * @property {Group} flameGrill
   * @property {Object3D} fourFeuxSpacerGrate
   * @property {Group} inductionRings
   * @property {Object3D} multiCooker
   * @property {Group} planca
   * @property {Group} traditionalPlate
   */

  /** @type {Promise<Group>} */
  get flameGrill() {
    return this.#getFlameGrill();
  }

  /** @type {Promise<Object3D>} */
  get fourFeuxSpacerGrate() {
    return this.#getFourFeuxSpacerGrate();
  }

  /** @type {Promise<Group>} */
  get inductionRings() {
    return this.#getInductionRings();
  }

  /** @type {Promise<Object3D>} */
  get multiCooker() {
    return this.#getMultiCooker();
  }

  /** @type {Promise<Group>} */
  get plancha() {
    return this.#getPlancha();
  }

  /** @type {Promise<Group>} */
  get tradPlateGroup() {
    return this.#getTraditionalPlate();
  }

  optionBases = new Group();

  elevenAnd5KBurners = new Group();
  fiveAnd11KBurners = new Group();
  fifteenAnd5KBurners = new Group();
  fifteenAnd11KBurners = new Group();
  elevenKBurners = new Group();
  fifteenKBurners = new Group();
  eighteenKBurner = new Group();
  leftGrateSpacerTabs = new Group();
  rightGrateSpacerTabs = new Group();
  fourFeuxSpacerTabs = new Group();

  #eighteenKBurnerGrate;
  #twoBurnerGrate = new Group();
  #traditionalPlate;
  #fiveKBurner;
  #elevenKBurner;
  #fifteenKBurner;
  #eighteenKBurner;

  /** @type {Cache} */
  #cachedAssets = {
    flameGrill: null,
    fourFeuxSpacerGrate: null,
    inductionRings: null,
    multiCooker: null,
    planca: null,
    traditionalPlate: null,
  };

  /** @type {AssetLoader} */
  #assets;

  /** @type {Materials} */
  #materials;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   */
  constructor(assets, materials) {
    this.#assets = assets;
    this.#materials = materials;
    this.#loadModels();
    this.#applyTextures();
    this.#setupShadows();
    this.#assemble11and5KBurners();
    this.#assemble5and11KBurners();
    this.#assemble15and5KBurners();
    this.#assemble15and11KBurners();
    this.#assemble11KBurners();
    this.#assemble15KBurners();
    this.#assemble18KBurner();
    this.#assembleGrateSpacerTabs();
  }

  /** @returns {Promise<Group>} */
  async #getFlameGrill() {
    if (!this.#cachedAssets.flameGrill) {
      this.#cachedAssets.flameGrill = await this.#assembleFlameGrill();
    }

    return this.#cachedAssets.flameGrill;
  }

  /** @returns {Promise<Object3D>} */
  async #getFourFeuxSpacerGrate() {
    if (!this.#cachedAssets.fourFeuxSpacerGrate) {
      this.#cachedAssets.fourFeuxSpacerGrate =
        await this.#assembleFourFeuxSpacerGrate();
    }

    return this.#cachedAssets.fourFeuxSpacerGrate;
  }

  /** @returns {Promise<Group>} */
  async #getInductionRings() {
    if (!this.#cachedAssets.inductionRings) {
      this.#cachedAssets.inductionRings = await this.#assembleInductionRings();
    }

    return this.#cachedAssets.inductionRings;
  }

  /** @returns {Promise<Object3D>} */
  async #getMultiCooker() {
    if (!this.#cachedAssets.multiCooker) {
      this.#cachedAssets.multiCooker = await this.#assembleMultiCooker();
    }

    return this.#cachedAssets.multiCooker;
  }

  /** @returns {Promise<Group>} */
  async #getPlancha() {
    if (!this.#cachedAssets.planca) {
      this.#cachedAssets.planca = await this.#assemblePlancha();
    }

    return this.#cachedAssets.planca;
  }

  /** @returns {Promise<Group>} */
  async #getTraditionalPlate() {
    if (!this.#cachedAssets.traditionalPlate) {
      this.#cachedAssets.traditionalPlate = await this.#assembleTradPlate();
    }

    return this.#cachedAssets.traditionalPlate;
  }

  /**
   * Assemble the plancha
   * @returns {Promise<Group>}
   */
  async #assembleFlameGrill() {
    const flameGrill = new Group();
    const flameGrillData = await this.#assets.flameGrillData;

    while (flameGrillData.scene.children.length) {
      flameGrill.add(flameGrillData.scene.children[0]);
    }

    this.#applyFlameGrillTextures(flameGrill);

    return flameGrill;
  }

  /**
   * Assemble the spacer grate for the 4-Feux range top
   * @returns {Promise<Object3D>}
   */
  async #assembleFourFeuxSpacerGrate() {
    const fourFeuxSpacerData = await this.#assets.fourFeuxSpacerData;
    const fourFeuxSpacerGrate = fourFeuxSpacerData.scene.children[0];

    this.#materials.applyEnamelCastIronMaterial(fourFeuxSpacerGrate);

    return fourFeuxSpacerGrate;
  }

  /**
   * Assemble the induction rings
   * @returns {Promise<Group>}
   */
  async #assembleInductionRings() {
    const inductionRings = new Group();
    const inductionRingsData = await this.#assets.inductionRingsData;

    while (inductionRingsData.scene.children.length) {
      inductionRings.add(inductionRingsData.scene.children[0]);
    }

    this.#applyInductionRingsTexture(inductionRings);

    return inductionRings;
  }

  /**
   * Assemble the multi-cooker
   * @returns {Promise<Object3D>}
   */
  async #assembleMultiCooker() {
    const multiCookerData = await this.#assets.multiCookerData;
    const multiCooker = multiCookerData.scene.children[0];

    this.#materials.applyStainlessSteelMaterial(multiCooker);

    return multiCooker;
  }

  /**
   * Assemble the plancha
   * @returns {Promise<Group>}
   */
  async #assemblePlancha() {
    const plancha = new Group();
    const planchaData = await this.#assets.planchaData;

    while (planchaData.scene.children.length) {
      plancha.add(planchaData.scene.children[0]);
    }

    this.#applyPlanchaTextures(plancha);

    return plancha;
  }

  /**
   * Assemble the traditional plate
   * @returns {Promise<Group>}
   */
  async #assembleTradPlate() {
    const traditionalPlateGroup = new Group();
    const traditionalPlateData = await this.#assets.traditionalPlateData;
    const traditionalPlate = traditionalPlateData.scene.children[0];
    const center18KBurner = this.#eighteenKBurner.clone();

    this.#materials.applyEnamelCastIronMaterial(traditionalPlate);

    traditionalPlateGroup.add(traditionalPlate, center18KBurner);

    return traditionalPlateGroup;
  }

  #loadModels() {
    // 18k Burner Grate
    this.#eighteenKBurnerGrate =
      this.#assets.eighteenKBurnerGrateData.scene.children[0];

    // 2 Burner Grate
    while (this.#assets.twoBurnerGrateData.scene.children.length) {
      this.#twoBurnerGrate.add(
        this.#assets.twoBurnerGrateData.scene.children[0]
      );
    }

    // 5k, 11k, 15k, 18k Burners
    [
      this.#fiveKBurner,
      this.#elevenKBurner,
      this.#fifteenKBurner,
      this.#eighteenKBurner,
    ] = buildBurners(this.#assets.allBurnersData.scene, this.#materials);

    // Range Top Option Bases
    while (this.#assets.rangetopPiecesData.scene.children.length) {
      this.optionBases.add(this.#assets.rangetopPiecesData.scene.children[0]);
    }
  }

  #applyTextures() {
    this.#materials.applyEnamelCastIronMaterial(
      this.#eighteenKBurnerGrate,
      ...this.#twoBurnerGrate.children
    );
  }

  #applyFlameGrillTextures(flameGrill) {
    this.#materials.applyStainlessSteelMaterial(
      flameGrill.getObjectByName('RO2_GG'), // flame grill cooking surface
      flameGrill.getObjectByName('RO2_GG001') // flame grill briquet holder
    );

    this.#materials.applyBrassMaterial(
      flameGrill.getObjectByName('RO2_Handel_GG'), // flame grill rear posts
      flameGrill.getObjectByName('RO2_Handel_GG001') // flame grill handles
    );

    // TODO: Find a better material for this
    this.#materials.applyEnamelCastIronMaterial(
      flameGrill.getObjectByName('RO2_GG002') // flame grill briquets
    );
  }

  #applyInductionRingsTexture(inductionRings) {
    this.#materials.applyMatteBlackMaterial(
      inductionRings.getObjectByName('RO2_2IR_P') // induction burner surface
    );
  }

  #applyPlanchaTextures(plancha) {
    this.#materials.applyStainlessSteelMaterial(
      plancha.getObjectByName('RO2_EP_T'), // plancha base
      plancha.getObjectByName('RO2_EP_O_T') // plancha cover
    );

    this.#materials.applyBrushedSSMaterial(
      plancha.getObjectByName('RO2_EP_O_Ring_T') // plancha handle bezel
    );
  }

  #setupShadows() {
    this.#twoBurnerGrate.getObjectByName('2_Burner_Grate').castShadow = true;
    this.#twoBurnerGrate.getObjectByName('Spacer').castShadow = true;
  }

  #assemble11and5KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front11KBurner = this.#elevenKBurner.clone();
    const back5KBurner = this.#fiveKBurner.clone();
    back5KBurner.translateZ(BACK_BURNER_OFFSET);

    this.elevenAnd5KBurners.add(twoBurnerGrate, front11KBurner, back5KBurner);
  }

  #assemble5and11KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front5KBurner = this.#fiveKBurner.clone();
    const back11KBurner = this.#elevenKBurner.clone();
    back11KBurner.translateZ(BACK_BURNER_OFFSET);

    this.fiveAnd11KBurners.add(twoBurnerGrate, front5KBurner, back11KBurner);
  }

  #assemble15and5KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front15KBurner = this.#fifteenKBurner.clone();
    const back5KBurner = this.#fiveKBurner.clone();
    back5KBurner.translateZ(BACK_BURNER_OFFSET);

    this.fifteenAnd5KBurners.add(twoBurnerGrate, front15KBurner, back5KBurner);
  }

  #assemble15and11KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front15KBurner = this.#fifteenKBurner.clone();
    const back11KBurner = this.#elevenKBurner.clone();
    back11KBurner.translateZ(BACK_BURNER_OFFSET);

    this.fifteenAnd11KBurners.add(
      twoBurnerGrate,
      front15KBurner,
      back11KBurner
    );
  }

  #assemble11KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front11KBurner = this.#elevenKBurner.clone();
    const back11KBurner = front11KBurner.clone();
    back11KBurner.translateZ(BACK_BURNER_OFFSET);

    this.elevenKBurners.add(twoBurnerGrate, front11KBurner, back11KBurner);
  }

  #assemble15KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front15KBurner = this.#fifteenKBurner.clone();
    const back15KBurner = front15KBurner.clone();
    back15KBurner.translateZ(BACK_BURNER_OFFSET);

    this.fifteenKBurners.add(twoBurnerGrate, front15KBurner, back15KBurner);
  }

  #assemble18KBurner() {
    const left18kBurnerGrate = this.#eighteenKBurnerGrate;
    const right18kBurnerGrate = left18kBurnerGrate.clone();
    right18kBurnerGrate.rotateZ(Math.PI);
    const center18kBurner = this.#eighteenKBurner.clone();

    this.eighteenKBurner.add(
      left18kBurnerGrate,
      right18kBurnerGrate,
      center18kBurner
    );
  }

  #assembleGrateSpacerTabs() {
    const leftSpacer = this.#twoBurnerGrate.getObjectByName('Spacer').clone();
    const rightSpacer = leftSpacer.clone();
    rightSpacer.rotateZ(Math.PI);
    rightSpacer.position.x += -0.0275;

    this.fourFeuxSpacerTabs.add(leftSpacer.clone(), rightSpacer.clone());

    this.leftGrateSpacerTabs.add(leftSpacer);

    rightSpacer.position.x += 0.312;
    this.rightGrateSpacerTabs.add(rightSpacer);
  }
}
