import { Group, Object3D } from 'three';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Volnay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Oven Door
  const ovenDoor = meshes.getObjectByName(MeshName.volnayOvenDoor).clone();
  ovenDoor.name = MeshName.ovenDoor;
  ovenDoor.position.x = -0.371;

  // Cupboard Door
  const cupboardDoor = meshes
    .getObjectByName(MeshName.volnayCupboardDoor)
    .clone();
  cupboardDoor.name = MeshName.cupboardDoor;
  cupboardDoor.position.x = 0.632;

  // Storage Drawer
  const storageDrawer = meshes
    .getObjectByName(MeshName.volnayStorageDrawer)
    .clone();
  storageDrawer.name = MeshName.storageDrawer;
  storageDrawer.position.x = -0.371;

  // Oven Vent Cover
  const leftOvenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCoverGroup)
    .clone();
  leftOvenVentCover.translateX(0.1);
  const rightOvenVentCover = leftOvenVentCover.clone();
  rightOvenVentCover.translateX(0.49);

  // Cooktop Hinge Plates
  const leftCooktopHingePlate = meshes
    .getObjectByName(MeshName.leftCooktopHingePlate)
    .clone();
  const rightCooktopHingePlate = meshes
    .getObjectByName(MeshName.rightCooktopHingePlate)
    .clone();

  return [
    ovenDoor,
    cupboardDoor,
    storageDrawer,
    leftOvenVentCover,
    rightOvenVentCover,
    leftCooktopHingePlate,
    rightCooktopHingePlate,
  ];
}

/**
 * All the classique trim parts for a Volnay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.465;
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.465;

  // Oven Door Bar Supports
  const ovenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  ovenDoorBarSupportLeft.name = MeshName.ovenDoorBarLeftSupport;
  ovenDoorBarSupportLeft.position.x = -0.455;
  const ovenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  ovenDoorBarSupportRight.name = MeshName.ovenDoorBarRightSupport;
  ovenDoorBarSupportRight.position.x = 0.085;

  // Cupboard Door Bar Supports
  const cupboardDoorBarSupportLeft = ovenDoorBarSupportLeft.clone();
  cupboardDoorBarSupportLeft.name = MeshName.cupboardBarLeftSupport;
  cupboardDoorBarSupportLeft.position.x = 0.213;
  const cupboardDoorBarSupportRight = ovenDoorBarSupportRight.clone();
  cupboardDoorBarSupportRight.name = MeshName.cupboardBarRightSupport;
  cupboardDoorBarSupportRight.position.x = 0.413;

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.classiqueVolnayOvenDoorBar)
    .clone();
  ovenDoorBar.name = MeshName.ovenDoorBar;
  ovenDoorBar.position.x = -0.18;

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  cupboardDoorBar.name = MeshName.cupboardDoorBar;
  cupboardDoorBar.position.x = 0.313;

  // Drawer Pulls
  const drawerPullLeft = meshes.getObjectByName(MeshName.drawerPull).clone();
  drawerPullLeft.name = MeshName.drawerLeftPull;
  drawerPullLeft.position.x = -0.36;
  const drawerPullRight = drawerPullLeft.clone();
  drawerPullRight.name = MeshName.drawerRightPull;
  drawerPullRight.position.x = -0.01;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportRight,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    ovenDoorBar,
    cupboardDoorBar,
    drawerPullLeft,
    drawerPullRight
  );
}

/**
 * All the moderne trim parts for a Volnay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.moderneVolnayOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = -0.182;

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneVolnayCupboardDoorBar)
    .clone();
  cupboardDoorBar.position.x = 0.327;

  // Oven Bar Supports
  const ovenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  ovenDoorBarSupportLeft.position.x = -0.455;
  const ovenDoorBarSupportRight = ovenDoorBarSupportLeft.clone();
  ovenDoorBarSupportRight.position.x = 0.09;

  // Cupboard Bar Supports
  const cupboardDoorBarSupportLeft = ovenDoorBarSupportRight.clone();
  cupboardDoorBarSupportLeft.position.x = 0.21;
  const cupboardDoorBarSupportRight = cupboardDoorBarSupportLeft.clone();
  cupboardDoorBarSupportRight.position.x = 0.41;

  // Storage Drawer Lip
  const drawerLip = meshes
    .getObjectByName(MeshName.moderneVolnayDrawerBottomLip)
    .clone();
  drawerLip.position.x = -0.183;

  return trim.add(
    ovenDoorBar,
    cupboardDoorBar,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    drawerLip
  );
}
