import { Group, Object3D } from 'three';
import * as common from './common.js';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Sully range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Mini Oven Vent Covers
  const leftOvenMiniVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCoverGroup)
    .clone();
  leftOvenMiniVentCover.name = MeshName.leftOvenMiniVentCover;
  leftOvenMiniVentCover.translateX(0.404);
  leftOvenMiniVentCover.visible = false;

  const rightOvenMiniVentCover = leftOvenMiniVentCover.clone();
  rightOvenMiniVentCover.name = MeshName.rightOvenMiniVentCover;
  rightOvenMiniVentCover.translateX(0.993);
  rightOvenMiniVentCover.visible = false;

  // Cooktop Hinge Plates
  const leftCooktopHingePlate = meshes
    .getObjectByName(MeshName.leftCooktopHingePlate)
    .clone();
  leftCooktopHingePlate.translateX(-0.2);

  const rightCooktopHingePlate = meshes
    .getObjectByName(MeshName.rightCooktopHingePlate)
    .clone();
  rightCooktopHingePlate.translateX(0.2);

  return [
    leftOvenMiniVentCover,
    rightOvenMiniVentCover,
    ...common.sullyOvenDoorsAndDrawers(meshes),
    leftCooktopHingePlate,
    rightCooktopHingePlate,
  ];
}

/**
 * All the classique trim parts for a Sully range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName('Towel_Bar_Support_Left')
    .clone();
  towelBarSupportLeft.position.x = -0.665;
  const towelBarSupportCenter = meshes
    .getObjectByName('Towel_Bar_Support_Center')
    .clone();
  const towelBarSupportRight = meshes
    .getObjectByName('Towel_Bar_Support_Right')
    .clone();
  towelBarSupportRight.position.x = 0.665;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportCenter,
    towelBarSupportRight,
    ...common.sullyOvenClassiqueTrim(meshes)
  );
}

/**
 * All the moderne trim parts for a Sully range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  return trim.add(...common.sullyOvenModerneTrim(meshes));
}
