import { Group, Object3D } from 'three';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Chassagne range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Cupboard Door
  const cupboardDoor = meshes.getObjectByName(MeshName.cupboardDoor).clone();
  cupboardDoor.position.x = -0.352;

  // Oven Door
  const ovenDoor = meshes.getObjectByName(MeshName.sullyOvenDoor).clone();
  ovenDoor.name = MeshName.ovenDoor;
  ovenDoor.position.x = 0.2;

  // Storage Drawer
  const storageDrawer = meshes
    .getObjectByName(MeshName.sullyStorageDrawer)
    .clone();
  storageDrawer.name = MeshName.storageDrawer;
  storageDrawer.position.x = 0.2;

  // Cupboard Vent Cover
  const cupboardVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCoverGroup)
    .clone();
  cupboardVentCover.translateX(0.554);

  // Oven Vent Cover
  const ovenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCoverGroup)
    .clone();
  ovenVentCover.translateX(0.55);

  // Cooktop Hinge Plates
  const leftCooktopHingePlate = meshes
    .getObjectByName(MeshName.leftCooktopHingePlate)
    .clone();
  leftCooktopHingePlate.translateX(-0.05);

  const rightCooktopHingePlate = meshes
    .getObjectByName(MeshName.rightCooktopHingePlate)
    .clone();
  rightCooktopHingePlate.translateX(0.05);

  return [
    cupboardDoor,
    ovenDoor,
    storageDrawer,
    cupboardVentCover,
    ovenVentCover,
    leftCooktopHingePlate,
    rightCooktopHingePlate,
  ];
}

/**
 * All the classique trim parts for a Chassagne range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName('Towel_Bar_Support_Left')
    .clone();
  towelBarSupportLeft.position.x = -0.515;
  const towelBarSupportRight = meshes
    .getObjectByName('Towel_Bar_Support_Right')
    .clone();
  towelBarSupportRight.position.x = 0.515;

  // Cupboard Door Bar Supports
  const cupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  cupboardDoorBarSupportLeft.name = MeshName.cupboardBarLeftSupport;
  cupboardDoorBarSupportLeft.position.x = -0.465;
  const cupboardDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  cupboardDoorBarSupportRight.name = MeshName.cupboardBarRightSupport;
  cupboardDoorBarSupportRight.position.x = -0.24;

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  cupboardDoorBar.name = MeshName.cupboardDoorBar;
  cupboardDoorBar.position.x = -0.34;

  // Oven Door Bar Supports
  const ovenDoorBarSupportLeft = cupboardDoorBarSupportLeft.clone();
  ovenDoorBarSupportLeft.name = MeshName.ovenDoorBarLeftSupport;
  ovenDoorBarSupportLeft.position.x = -0.11;
  const ovenDoorBarSupportRight = cupboardDoorBarSupportRight.clone();
  ovenDoorBarSupportRight.name = MeshName.ovenDoorBarRightSupport;
  ovenDoorBarSupportRight.position.x = 0.507;

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.classiqueSullyOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = 0.2;
  ovenDoorBar.name = MeshName.ovenDoorBar;

  // Drawer Pulls
  const drawerPullLeft = meshes.getObjectByName(MeshName.drawerPull).clone();
  drawerPullLeft.name = MeshName.drawerLeftPull;
  drawerPullLeft.position.x = -0.01;
  const drawerPullRight = drawerPullLeft.clone();
  drawerPullRight.name = MeshName.drawerRightPull;
  drawerPullRight.position.x = 0.39;

  return trim.add(
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    cupboardDoorBar,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    ovenDoorBar,
    towelBarSupportLeft,
    towelBarSupportRight,
    drawerPullLeft,
    drawerPullRight
  );
}

/**
 * All the moderne trim parts for a Chassagne range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  cupboardDoorBar.position.x = -0.35;

  // Cupboard Bar Supports
  const cupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  cupboardDoorBarSupportLeft.position.x = -0.465;
  const cupboardDoorBarSupportRight = cupboardDoorBarSupportLeft.clone();
  cupboardDoorBarSupportRight.position.x = -0.24;

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.moderneSullyOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = 0.2;

  // Oven Bar Supports
  const ovenDoorBarSupportLeft = cupboardDoorBarSupportRight.clone();
  ovenDoorBarSupportLeft.position.x = -0.115;
  const ovenDoorBarSupportRight = ovenDoorBarSupportLeft.clone();
  ovenDoorBarSupportRight.position.x = 0.51;

  // Storage Drawer Lips
  const drawerLip = meshes
    .getObjectByName(MeshName.moderneSullyDrawerBottomLip)
    .clone();
  drawerLip.position.x = 0.2;

  return trim.add(
    cupboardDoorBar,
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    ovenDoorBar,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    drawerLip
  );
}
