import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Oven, Position, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, Object.<string, ?string>>>}
   */
  selectedKnobs = {
    farLeft: {
      optionBack: {
        type: null,
        position: Position.back,
      },
      optionFront: {
        type: null,
        position: Position.front,
      },
      optionCenter: {
        type: null,
        position: Position.back,
      },
      cupboard: {
        type: Oven.leftVertConv,
        position: null,
      },
    },
    right: {
      baseLeftBurnerFront: {
        type: null,
        position: Position.leftFront,
      },
      baseLeftBurnerBack: {
        type: null,
        position: Position.leftBack,
      },
      baseCenterBurner: {
        type: Burner.gasBurner,
        position: Position.left,
      },
      baseRightBurnerBack: {
        type: Burner.gasBurner,
        position: Position.rightBack,
      },
      baseRightBurnerFront: {
        type: Burner.gasBurner,
        position: Position.rightFront,
      },
      oven: {
        type: Oven.convection,
        position: null,
      },
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);
  }

  threeFeuxBaseTop() {
    this.selectedKnobs.right.baseLeftBurnerFront.type = null;
    this.selectedKnobs.right.baseLeftBurnerBack.type = null;
    this.selectedKnobs.right.baseCenterBurner.type = Burner.gasBurner;
    this.selectedKnobs.right.baseRightBurnerBack.type = Burner.gasBurner;
    this.selectedKnobs.right.baseRightBurnerFront.type = Burner.gasBurner;

    this.updateRightKnobs();
  }

  fourFeuxBaseTop() {
    this.selectedKnobs.right.baseLeftBurnerFront.type = Burner.gasBurner;
    this.selectedKnobs.right.baseLeftBurnerBack.type = Burner.gasBurner;
    this.selectedKnobs.right.baseCenterBurner.type = null;
    this.selectedKnobs.right.baseRightBurnerBack.type = Burner.gasBurner;
    this.selectedKnobs.right.baseRightBurnerFront.type = Burner.gasBurner;

    this.updateRightKnobs();
  }

  /**
   * Change the main oven to gas or electric convection
   * @param {string} ovenType
   */
  changeLeftOven(ovenType) {
    this.selectedKnobs.right.oven.type = ovenType;
    this.updateRightKnobs();
  }

  /** Update the knobs on the range's left */
  updateFarLeftKnobs() {
    this.updateKnobRegion(Region.farLeft, {
      maxKnobs: 3,
      offset: 0.53,
      gap: 0.1,
    });
  }

  /** Update the knobs on the range's right */
  updateRightKnobs() {
    this.updateKnobRegion(Region.right, {
      maxKnobs: 5,
      offset: 0.9,
      gap: 0.11,
    });
  }
}
