import { Group, Object3D } from 'three';

import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Beaune range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Oven Door
  const ovenDoor = meshes.getObjectByName(MeshName.clunyOvenDoor).clone();
  ovenDoor.name = MeshName.ovenDoor;
  ovenDoor.position.x = -0.452;

  // Cupboard Door
  const cupboardDoor = meshes.getObjectByName(MeshName.cupboardDoor).clone();
  cupboardDoor.position.x = 0.252;

  // Storage Drawer
  const storageDrawer = meshes
    .getObjectByName(MeshName.clunyStorageDrawer)
    .clone();
  storageDrawer.name = MeshName.storageDrawer;
  storageDrawer.position.x = -0.452;

  // Oven Vent Covers
  const leftOvenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCoverGroup)
    .clone();
  leftOvenVentCover.translateX(0.14);
  const rightOvenVentCover = leftOvenVentCover.clone();
  rightOvenVentCover.translateX(0.41);

  // Cooktop Hinge Plates
  const leftCooktopHingePlate = meshes
    .getObjectByName(MeshName.leftCooktopHingePlate)
    .clone();
  leftCooktopHingePlate.translateX(0.05);

  const rightCooktopHingePlate = meshes
    .getObjectByName(MeshName.rightCooktopHingePlate)
    .clone();
  rightCooktopHingePlate.translateX(-0.05);

  return [
    ovenDoor,
    cupboardDoor,
    storageDrawer,
    leftOvenVentCover,
    rightOvenVentCover,
    leftCooktopHingePlate,
    rightCooktopHingePlate,
  ];
}

/**
 * All the classique trim parts for a Beaune range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.41;
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.41;

  // Oven Door Bar Supports
  const ovenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  ovenDoorBarSupportLeft.name = MeshName.ovenDoorBarLeftSupport;
  ovenDoorBarSupportLeft.position.x = -0.41;
  const ovenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  ovenDoorBarSupportRight.name = MeshName.ovenDoorBarRightSupport;
  ovenDoorBarSupportRight.position.x = 0.01;

  // Cupboard Door Bar Supports
  const cupboardBarSupportLeft = ovenDoorBarSupportLeft.clone();
  cupboardBarSupportLeft.name = MeshName.cupboardBarLeftSupport;
  cupboardBarSupportLeft.position.x = 0.13;
  const cupboardBarSupportRight = ovenDoorBarSupportRight.clone();
  cupboardBarSupportRight.name = MeshName.cupboardBarRightSupport;
  cupboardBarSupportRight.position.x = 0.36;

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.classiqueClunyOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = -0.2;

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  cupboardDoorBar.position.x = 0.245;

  // Drawer Pulls
  const drawerPullLeft = meshes.getObjectByName(MeshName.drawerPull).clone();
  drawerPullLeft.name = MeshName.drawerLeftPull;
  drawerPullLeft.position.x = -0.36;
  const drawerPullRight = drawerPullLeft.clone();
  drawerPullRight.name = MeshName.drawerRightPull;
  drawerPullRight.position.x = -0.04;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportRight,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    cupboardBarSupportLeft,
    cupboardBarSupportRight,
    ovenDoorBar,
    cupboardDoorBar,
    drawerPullLeft,
    drawerPullRight
  );
}

/**
 * All the moderne trim parts for a Beaune range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.moderneClunyOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = -0.2;

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  cupboardDoorBar.position.x = 0.25;

  // Oven Bar Supports
  const ovenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  ovenDoorBarSupportLeft.position.x = -0.414;
  const ovenDoorBarSupportRight = ovenDoorBarSupportLeft.clone();
  ovenDoorBarSupportRight.position.x = 0.01;

  // Cupboard Bar Supports
  const cupboardBarSupportLeft = ovenDoorBarSupportRight.clone();
  cupboardBarSupportLeft.position.x = 0.125;
  const cupboardBarSupportRight = cupboardBarSupportLeft.clone();
  cupboardBarSupportRight.position.x = 0.36;

  // Storage Drawer Lip
  const drawerLip = meshes
    .getObjectByName(MeshName.moderneClunyDrawerBottomLip)
    .clone();
  drawerLip.position.x = -0.2;

  return trim.add(
    ovenDoorBar,
    cupboardDoorBar,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    cupboardBarSupportLeft,
    cupboardBarSupportRight,
    drawerLip
  );
}
