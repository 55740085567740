import { Group, Object3D } from 'three';

import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Cormatin range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Petite Oven Door
  const petiteOvenDoor = meshes
    .getObjectByName(MeshName.sullyStorageDrawer)
    .clone();
  petiteOvenDoor.name = MeshName.petiteOvenDoor;
  petiteOvenDoor.position.x = 0;
  petiteOvenDoor.translateY(0.39);

  // Oven Door
  const ovenDoor = meshes.getObjectByName(MeshName.sullyOvenDoor).clone();
  ovenDoor.name = MeshName.ovenDoor;
  ovenDoor.position.x = 0;
  ovenDoor.translateY(-0.22);

  // Oven Vent Cover
  const ovenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCoverGroup)
    .clone();
  ovenVentCover.translateX(0.34);

  // Cooktop Hinge Plates
  const leftCooktopHingePlate = meshes
    .getObjectByName(MeshName.leftCooktopHingePlate)
    .clone();
  leftCooktopHingePlate.translateX(0.15);

  const rightCooktopHingePlate = meshes
    .getObjectByName(MeshName.rightCooktopHingePlate)
    .clone();
  rightCooktopHingePlate.translateX(-0.15);

  return [
    petiteOvenDoor,
    ovenDoor,
    ovenVentCover,
    leftCooktopHingePlate,
    rightCooktopHingePlate,
  ];
}

/**
 * All the classique trim parts for a Cormatin range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.31;
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.31;

  // Petite Oven Door Bar Supports
  const petiteOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  petiteOvenDoorBarSupportLeft.name = MeshName.petiteOvenDoorBarLeftSupport;
  petiteOvenDoorBarSupportLeft.position.x = -0.31;
  const petiteOvenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  petiteOvenDoorBarSupportRight.name = MeshName.petiteOvenDoorBarRightSupport;
  petiteOvenDoorBarSupportRight.position.x = 0.31;

  // Oven Door Bar Supports
  const ovenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  ovenDoorBarSupportLeft.name = MeshName.ovenDoorBarLeftSupport;
  ovenDoorBarSupportLeft.position.y += -0.22;
  const ovenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  ovenDoorBarSupportRight.name = MeshName.ovenDoorBarRightSupport;
  ovenDoorBarSupportRight.position.y += -0.22;

  // Petite Oven Door Bar
  const petiteOvenDoorBar = meshes
    .getObjectByName(MeshName.classiqueSullyOvenDoorBar)
    .clone();
  petiteOvenDoorBar.name = MeshName.petiteOvenDoorBar;
  petiteOvenDoorBar.position.x = 0;

  // Oven Door Bar
  const ovenDoorBar = petiteOvenDoorBar.clone();
  ovenDoorBar.name = MeshName.ovenDoorBar;
  ovenDoorBar.position.y += -0.22;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportRight,
    petiteOvenDoorBarSupportLeft,
    petiteOvenDoorBarSupportRight,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    petiteOvenDoorBar,
    ovenDoorBar
  );
}

/**
 * All the classique trim parts for a Cormatin range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Petite Oven Door Bar
  const petiteOvenDoorBar = meshes
    .getObjectByName(MeshName.moderneSullyOvenDoorBar)
    .clone();
  petiteOvenDoorBar.position.x = 0;

  // Oven Door Bar
  const ovenDoorBar = petiteOvenDoorBar.clone();
  ovenDoorBar.position.y += -0.22;

  // Petite Oven Bar Supports
  const petiteOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  petiteOvenDoorBarSupportLeft.position.x = -0.315;
  const petiteOvenDoorBarSupportRight = petiteOvenDoorBarSupportLeft.clone();
  petiteOvenDoorBarSupportRight.position.x = 0.315;

  // Oven Bar Supports
  const ovenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  ovenDoorBarSupportLeft.position.y += -0.22;
  const ovenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  ovenDoorBarSupportRight.position.y += -0.22;

  return trim.add(
    petiteOvenDoorBar,
    ovenDoorBar,
    petiteOvenDoorBarSupportLeft,
    petiteOvenDoorBarSupportRight,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight
  );
}
