import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Oven, Position, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, Object.<string, ?string>>>}
   */
  selectedKnobs = {
    left: {
      oven: {
        type: Oven.electric,
        position: null,
      },
    },
    center: {
      baseLeftBurnerFront: {
        type: Burner.gasBurner,
        position: Position.leftFront,
      },
      baseLeftBurnerBack: {
        type: Burner.gasBurner,
        position: Position.leftBack,
      },
      baseCenterBurnerBack: {
        type: Burner.gasBurner,
        position: Position.back,
      },
      baseCenterBurnerFront: {
        type: Burner.gasBurner,
        position: Position.front,
      },
      baseRightBurnerBack: {
        type: Burner.gasBurner,
        position: Position.rightBack,
      },
      baseRightBurnerFront: {
        type: Burner.gasBurner,
        position: Position.rightFront,
      },
    },
    right: {
      oven: {
        type: Oven.rightVertConv,
        position: null,
      },
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);

    this.updateLeftKnobs();
    this.updateCenterKnobs();
    this.updateRightKnobs();
  }

  /** Place the main oven knob */
  updateLeftKnobs() {
    this.updateKnobRegion(Region.left, {
      maxKnobs: 1,
      offset: 0.62,
      gap: 0.09,
    });
  }

  /** Place the burner knobs */
  updateCenterKnobs() {
    this.updateKnobRegion(Region.center, {
      maxKnobs: 6,
      offset: 0.73,
      gap: 0.09,
    });
  }

  updateRightKnobs() {
    this.updateKnobRegion(Region.right, {
      maxKnobs: 1,
      offset: 1.293,
      gap: 0.09,
    });
  }

  /**
   * Change the oven to gas or electric
   * @param {string} ovenType
   */
  changeLeftOven(ovenType) {
    this.selectedKnobs.left.oven.type = ovenType;
    this.updateLeftKnobs();
  }
}
