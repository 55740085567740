import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Oven, Position, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, Object.<string, ?string>>>}
   */
  selectedKnobs = {
    center: {
      leftOven: {
        type: Oven.gas,
        position: null,
      },
      baseLeftBurnerFront: {
        type: Burner.gasBurner,
        position: Position.leftFront,
      },
      baseLeftBurnerBack: {
        type: Burner.gasBurner,
        position: Position.leftBack,
      },
      baseBurnerCenter: {
        type: Burner.gasBurner,
        position: Position.center,
      },
      baseRightBurnerBack: {
        type: Burner.gasBurner,
        position: Position.rightBack,
      },
      baseRightBurnerFront: {
        type: Burner.gasBurner,
        position: Position.rightFront,
      },
      rightOven: {
        type: Oven.electric,
        position: null,
      },
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);

    this.updateCenterKnobs();
  }

  /**
   * Change the left oven to gas or electric
   * @param {string} ovenType
   */
  changeLeftOven(ovenType) {
    this.selectedKnobs.center.leftOven.type = ovenType;
    this.updateCenterKnobs();
  }

  /**
   * Change the right oven to gas or electric
   * @param {string} ovenType
   */
  changeRightOven(ovenType) {
    this.selectedKnobs.center.rightOven.type = ovenType;
    this.updateCenterKnobs();
  }

  /** Update all of the Volnay's knobs */
  updateCenterKnobs() {
    this.updateKnobRegion(Region.center, {
      maxKnobs: 7,
      offset: 0.63,
      gap: 0.11,
    });
  }
}
