import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Cupboard, Oven, Position, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, Object.<string, ?string>>>}
   */
  selectedKnobs = {
    farLeft: {
      optionBack: {
        type: null,
        position: Position.back,
      },
      optionFront: {
        type: null,
        position: Position.front,
      },
      optionCenter: {
        type: null,
        position: Position.back,
      },
      cupboard: {
        type: Cupboard.warming,
        position: null,
      },
    },
    left: {
      oven: {
        type: Oven.gas,
        position: null,
      },
      baseBurnerFront: {
        type: Burner.gasBurner,
        position: Position.leftFront,
      },
      baseBurnerBack: {
        type: Burner.gasBurner,
        position: Position.leftBack,
      },
      baseBurnerCenter: {
        type: Burner.gasBurner,
        position: Position.center,
      },
    },
    right: {
      baseBurnerBack: {
        type: Burner.gasBurner,
        position: Position.rightBack,
      },
      baseBurnerFront: {
        type: Burner.gasBurner,
        position: Position.rightFront,
      },
      oven: {
        type: Oven.convection,
        position: null,
      },
    },
    farRight: {
      optionBack: {
        type: null,
        position: Position.back,
      },
      optionFront: {
        type: null,
        position: Position.front,
      },
      optionCenter: {
        type: null,
        position: Position.back,
      },
      cupboard: {
        type: Cupboard.warming,
        position: null,
      },
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);
  }

  /**
   * Change the left oven to gas or electric
   * @param {string} ovenType
   */
  changeLeftOven(ovenType) {
    this.selectedKnobs.left.oven.type = ovenType;
    this.updateLeftKnobs();
  }

  /**
   * Change the right oven to gas or electric
   * @param {string} ovenType
   */
  changeRightOven(ovenType) {
    this.selectedKnobs.right.oven.type = ovenType;
    this.updateRightKnobs();
  }

  /**
   * Change the selected cupboard to have a knob for a warming cupboard
   * @param {string} region
   */
  selectWarmingCupboard(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.cupboard.type = Cupboard.warming;
        this.updateFarLeftKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.cupboard.type = Cupboard.warming;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Remove the warming cupboard knob from the selected region
   * @param {string} region
   */
  selectStorageCupboard(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.cupboard.type = null;
        this.updateFarLeftKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.cupboard.type = null;
        this.updateFarRightKnobs();
        break;
    }
  }

  /** Update the knobs on the range's far left */
  updateFarLeftKnobs() {
    this.updateKnobRegion(Region.farLeft, {
      maxKnobs: 3,
      offset: 0.2,
      gap: 0.1,
    });
  }

  /** Update the knobs on the range's left */
  updateLeftKnobs() {
    this.updateKnobRegion(Region.left, {
      maxKnobs: 4,
      offset: 0.63,
      gap: 0.11,
    });
  }

  /** Update the knobs on the range's right */
  updateRightKnobs() {
    this.updateKnobRegion(Region.right, {
      maxKnobs: 3,
      offset: 1.07,
      gap: 0.11,
    });
  }

  /** Update the knobs on the range's far right */
  updateFarRightKnobs() {
    this.updateKnobRegion(Region.farRight, {
      maxKnobs: 3,
      offset: 1.52,
      gap: 0.1,
    });
  }
}
