import { Group, Object3D } from 'three';
import { AssetLoader } from '../../shared/AssetLoader';
import { Materials } from '../../shared/Materials';
import { MeshName } from '../../shared/Enums';

export class KnobModels {
  classiqueKnob = new Group();
  moderneKnob = new Group();

  convectionOvenDial = new Group();
  convectionOvenDialAlt = new Group();
  electricOvenDial = new Group();
  leftVertConvOvenDial = new Group();
  rightVertConvOvenDial = new Group();
  petiteOvenDial = new Group();
  gasOvenDial = new Group();
  inductionDial = new Group();
  knobBurnerDial = new Group();
  multiCookerDial = new Group();
  planchaDial = new Group();
  warmingCupboardDialLeft = new Group();
  warmingCupboardDialRight = new Group();

  /** @type Object3D */
  positionBox;

  /** @type Object3D */
  positionDot;

  /** @type Object3D */
  #convectionToggleDial;

  #toggleSwitch = new Group();
  #leds = new Group();
  #amberLight = new Group();
  #redLight = new Group();

  /** @type {AssetLoader} */
  #assets;

  /** @type {Materials} */
  #materials;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   */
  constructor(assets, materials) {
    this.#assets = assets;
    this.#materials = materials;
    this.#loadModels();
    this.#setupShadows();
    this.#createIndicatorLights();
    this.#applyMaterials();
    this.#assembleConvectionOvenDial();
    this.#assembleConvectionOvenDialAlt();
    this.#assembleElectricOvenDial();
    this.#assembleLeftVertConvOvenDial();
    this.#assembleRightVertConvOvenDial();
    this.#assemblePetiteOvenDial();
    this.#assembleMultiCookerDial();
    this.#assembleWarmingCupboardDials();
    this.#assemblePlanchaDial();
  }

  /**
   * Change the LED bezels to brass or chrome
   * @param {string} trim
   */
  changeLEDBezels(trim) {
    const dialsWithLEDs = [
      this.convectionOvenDial,
      this.convectionOvenDialAlt,
      this.electricOvenDial,
      this.leftVertConvOvenDial,
      this.rightVertConvOvenDial,
      this.petiteOvenDial,
      this.multiCookerDial,
      this.planchaDial,
      this.warmingCupboardDialLeft,
      this.warmingCupboardDialRight,
    ];

    dialsWithLEDs.forEach((dial) => {
      dial.children.forEach((dialElement) => {
        // @ts-ignore
        if (!dialElement.isGroup) return;
        const ledBezel = dialElement.getObjectByName('bezel');
        if (!ledBezel) return;

        this.#materials.changeTrim(trim, [ledBezel]);
      });
    });
  }

  #loadModels() {
    while (this.#assets.controlPanelPartsData.scene.children.length) {
      const child = this.#assets.controlPanelPartsData.scene.children[0];

      switch (child.name) {
        case 'Dial_Burner':
          this.knobBurnerDial.add(child);
          break;
        case 'Dial_Convection_Toggle':
          this.#convectionToggleDial = child;
          this.#assets.controlPanelPartsData.scene.remove(child);
          break;
        case 'Dial_Electric_Oven':
          this.convectionOvenDial.add(child.clone());
          this.convectionOvenDialAlt.add(child.clone());
          this.electricOvenDial.add(child.clone());
          this.leftVertConvOvenDial.add(child.clone());
          this.rightVertConvOvenDial.add(child);
          break;
        case 'Dial_Electric_Plancha':
          this.planchaDial.add(child);
          break;
        case 'Dial_Gas_Oven':
          this.gasOvenDial.add(child);
          break;
        case 'Dial_Induction':
          this.inductionDial.add(child);
          break;
        case 'Dial_MultiCooker':
          this.multiCookerDial.add(child);
          break;
        case 'Dial_Petite_Oven':
          this.petiteOvenDial.add(child);
          break;
        case 'Dial_Position_Box':
          this.positionBox = child;
          this.#assets.controlPanelPartsData.scene.remove(child);
          this.knobBurnerDial.add(this.positionBox.clone());
          this.planchaDial.add(this.positionBox.clone());
          this.multiCookerDial.add(this.positionBox.clone());
          this.inductionDial.add(this.positionBox.clone());
          break;
        case 'Dial_Position_Dot':
          this.positionDot = child;
          this.#assets.controlPanelPartsData.scene.remove(child);
          break;
        case 'Dial_Warming_Cupboard':
          this.warmingCupboardDialLeft.add(child.clone());
          this.warmingCupboardDialRight.add(child);
          break;
        case 'Indicator_Light':
        case 'Indicator_Light_Bezel':
          this.#leds.add(child);
          break;
        case 'Knob_Classique':
        case 'Knob_Classique_Black':
          this.classiqueKnob.add(child);
          break;
        case 'Knob_Moderne':
        case 'Knob_Moderne_Black':
          this.moderneKnob.add(child);
          break;
        case 'Toggle_Switch':
        case 'Toggle_Switch_Bezel':
          this.#toggleSwitch.add(child);
          break;
      }
    }

    this.moderneKnob.translateX(-0.2);
  }

  #setupShadows() {
    this.classiqueKnob.getObjectByName(
      MeshName.classiqueKnob
    ).castShadow = true;
    this.moderneKnob.getObjectByName(MeshName.moderneKnob).castShadow = true;
    this.#toggleSwitch.getObjectByName('Toggle_Switch').castShadow = true;
    this.#toggleSwitch.getObjectByName('Toggle_Switch_Bezel').castShadow = true;
  }

  #createIndicatorLights() {
    const amberLED = this.#leds.getObjectByName('Indicator_Light').clone();
    amberLED.name = 'led';
    const amberBezel = this.#leds
      .getObjectByName('Indicator_Light_Bezel')
      .clone();
    amberBezel.name = 'bezel';
    this.#amberLight.add(amberLED, amberBezel);

    const redLED = amberLED.clone();
    const redBezel = amberBezel.clone();
    this.#redLight.add(redLED, redBezel);
  }

  #applyMaterials() {
    this.#materials.applyBrassMaterial(
      this.classiqueKnob.getObjectByName('Knob_Classique'),
      this.#amberLight.getObjectByName('bezel'),
      this.#redLight.getObjectByName('bezel')
    );

    this.#materials.applyChromeMaterial(
      this.moderneKnob.getObjectByName('Knob_Moderne'),
      this.#toggleSwitch.getObjectByName('Toggle_Switch'),
      this.#toggleSwitch.getObjectByName('Toggle_Switch_Bezel')
    );

    this.#materials.applyAmberLightMaterial(
      this.#amberLight.getObjectByName('led')
    );

    this.#materials.applyRedLightMaterial(
      this.#redLight.getObjectByName('led')
    );
  }

  #assembleConvectionOvenDial() {
    const amberLight = this.#amberLight.clone();
    const redLight = this.#redLight.clone();
    redLight.translateY(-0.02);
    const toggleSwitch = this.#toggleSwitch.clone();
    const toggleLabel = this.#convectionToggleDial.clone();

    this.convectionOvenDial.add(
      amberLight,
      redLight,
      toggleSwitch,
      toggleLabel
    );
  }

  /** This convection oven dial has the LED lights on the left */
  #assembleConvectionOvenDialAlt() {
    const amberLight = this.#amberLight.clone();
    amberLight.translateX(-0.11);
    const redLight = this.#redLight.clone();
    redLight.translateX(-0.11);
    redLight.translateY(-0.02);
    const toggleSwitch = this.#toggleSwitch.clone();
    toggleSwitch.translateX(-0.027);
    const toggleLabel = this.#convectionToggleDial.clone();
    toggleLabel.translateX(-0.027);

    this.convectionOvenDialAlt.add(
      amberLight,
      redLight,
      toggleSwitch,
      toggleLabel
    );
  }

  #assembleElectricOvenDial() {
    const amberLight = this.#amberLight.clone();
    const redLight = this.#redLight.clone();
    redLight.translateY(-0.02);

    this.electricOvenDial.add(amberLight, redLight);
  }

  #assembleLeftVertConvOvenDial() {
    const amberLight = this.#amberLight.clone();
    const redLight = this.#redLight.clone();
    redLight.translateY(-0.02);
    const toggleSwitch = this.#toggleSwitch.clone();
    const toggleLabel = this.#convectionToggleDial.clone();

    this.leftVertConvOvenDial.add(
      amberLight,
      redLight,
      toggleSwitch,
      toggleLabel
    );
  }

  #assembleRightVertConvOvenDial() {
    const amberLight = this.#amberLight.clone();
    amberLight.translateX(0.005);
    const redLight = this.#redLight.clone();
    redLight.translateX(0.005);
    redLight.translateY(-0.02);
    const toggleSwitch = this.#toggleSwitch.clone();
    toggleSwitch.translateX(-0.13);
    toggleSwitch.translateY(-0.007);
    const toggleLabel = this.#convectionToggleDial.clone();
    toggleLabel.translateX(-0.13);
    toggleLabel.translateY(-0.007);

    this.rightVertConvOvenDial.add(
      amberLight,
      redLight,
      toggleSwitch,
      toggleLabel
    );
  }

  #assemblePetiteOvenDial() {
    const amberLight = this.#amberLight.clone();
    amberLight.translateX(-0.001);
    const redLight = this.#redLight.clone();
    redLight.translateX(-0.001);
    redLight.translateY(-0.02);

    this.petiteOvenDial.add(amberLight, redLight);
  }

  #assembleMultiCookerDial() {
    const amberLight = this.#amberLight.clone();
    amberLight.translateX(0.01);
    amberLight.translateY(0.005);
    const redLight = this.#redLight.clone();
    redLight.translateX(-0.163);
    redLight.translateY(0.018);
    const toggleSwitch = this.#toggleSwitch.clone();
    toggleSwitch.translateX(-0.165);
    toggleSwitch.translateY(0.039);

    this.multiCookerDial.add(amberLight, redLight, toggleSwitch);
  }

  #assembleWarmingCupboardDials() {
    const leftAmberLight = this.#amberLight.clone();
    leftAmberLight.translateX(-0.095);
    leftAmberLight.translateY(-0.02);

    const rightAmberLight = this.#amberLight.clone();
    rightAmberLight.translateX(-0.005);
    rightAmberLight.translateY(-0.02);

    this.warmingCupboardDialLeft.add(leftAmberLight);
    this.warmingCupboardDialRight.add(rightAmberLight);
  }

  #assemblePlanchaDial() {
    const redLight = this.#redLight.clone();
    redLight.translateX(-0.005);
    redLight.translateY(-0.018);

    this.planchaDial.add(redLight);
  }
}
