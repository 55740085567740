import {
  CubeTextureLoader,
  EquirectangularReflectionMapping,
  Scene,
} from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

// @ts-ignore
import hdrFile from '/assets/envmap/MR_INT-001_NaturalStudio_NAD.hdr?url';

// @ts-ignore
import px from '/assets/envmap/px.png?url';
// @ts-ignore
import nx from '/assets/envmap/nx.png?url';
// @ts-ignore
import py from '/assets/envmap/py.png?url';
// @ts-ignore
import ny from '/assets/envmap/ny.png?url';
// @ts-ignore
import pz from '/assets/envmap/pz.png?url';
// @ts-ignore
import nz from '/assets/envmap/nz.png?url';

/**
 * Load the environment map which provides most of the lighting in the scene.
 * @param {Scene} scene
 */
export function loadEnvMap(scene, callback) {
  // const cubeTextureLoader = new CubeTextureLoader();

  // const texture = cubeTextureLoader.load([px, nx, py, ny, pz, nz]);

  // scene.environment = texture;
  // scene.environmentIntensity = 3;
  // callback();

  new RGBELoader().load(hdrFile, function (texture) {
    texture.mapping = EquirectangularReflectionMapping;
    scene.environment = texture;
    // scene.environmentRotation.set(0.1, 0, 0);
    callback();
  });
}
