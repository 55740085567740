import { Group, Mesh, Object3D } from 'three';
import * as common from './common.js';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Chagny range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Cooktop Hinge Plates
  const leftCooktopHingePlate = meshes
    .getObjectByName(MeshName.leftCooktopHingePlate)
    .clone();
  const rightCooktopHingePlate = meshes
    .getObjectByName(MeshName.rightCooktopHingePlate)
    .clone();

  return [
    ...common.chagnyOvenDoorsAndDrawers(meshes),
    leftCooktopHingePlate,
    rightCooktopHingePlate,
  ];
}

/**
 * All the classique trim parts for a Chagny range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.46;
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.46;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportRight,
    ...common.chagnyOvenClassiqueTrim(meshes)
  );
}

/**
 * All the moderne trim parts for a Chagny range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  return trim.add(...common.chagnyOvenModerneTrim(meshes));
}
